import { action, observable, makeObservable } from 'mobx';

// Helpers
import request from '~/bin/httpRequest';
import { apiAddons, getConfigMC, queryFunction, getEsimConfig, getPagoContraEntregaConfig } from '~/store/Query/api';

// TODO: en v2 se elimina funcion consulrCart
/**
 * Consult the cart or order detail.
 * @param {string} SessionId - is required.
 */
export const consultCart = (SessionId) => {
  const options = {
    method: 'POST',
    url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/sessionvalidate`,
  };

  return request.genericHandler(options, SessionId).then((res) => {
    let callback = { action: 'guestSession', success: false };
    if (!res.error) {
      callback = { ...callback, data: res.data, success: true };
    } else {
      callback = { ...callback, error: res.error };
    }
    return callback;
  });
};

export const genericFunction = (options, sessionIdDisable, actionCallback) => request.genericHandler(options, sessionIdDisable).then((res) => {
  let callback = { action: actionCallback, success: false };
  if (!res.error) {
    callback = {
      ...callback,
      data: res.data,
      success: true,
    };
  } else {
    callback = {
      ...callback,
      error: res.error,
    };
  }
  return callback;
});

class OrderStore {
  // @observable addCart = observable.map();
  getCartInfo = observable.map();

  getCartConfig = observable.map();

  getEsimConfig = observable.map();

  getPagoContraEntregaConfig = observable.map();

  shippingAddress = observable.map();

  billinggAddress = observable.map();

  maxActivClientResponse = observable.map();

  maxActivClientPostpagoResponse = observable.map();

  validateNumberResponse = observable.map();

  addressListResponse = observable.map();

  warnedUser = false;

  validateCartTrigger = null;

  constructor() {
    makeObservable(this, {
      getCartInfo: observable,
      getCartConfig: observable,
      getEsimConfig: observable,
      getPagoContraEntregaConfig: observable,
      shippingAddress: observable,
      billinggAddress: observable,
      maxActivClientResponse: observable,
      maxActivClientPostpagoResponse: observable,
      validateNumberResponse: observable,
      addressListResponse: observable,
      warnedUser: observable,
      validateCartTrigger: observable,
      getCart: action,
      setWarnedUser: action,
      getConfig: action,
      getConfigEsim: action,
      getConfigPagoContraEntrega: action,
      getExchangeRate: action,
      getRecommendations: action,
      getAddons: action,
      addToCart: action,
      deleteToCart: action,
      validateCart: action,
      validateAddon: action,
      refreshOrder: action,
      addShippingAddress: action,
      uploadDocument: action,
      addBillingAddress: action,
      getNit: action,
      validateNit: action,
      validateMaxActiveClient: action,
      validateMaxActivations: action,
      validateNumber: action,
      generarToken: action,
      validarToken: action,
      orderDocument: action,
      contracts: action,
      documentsUpload: action,
      addressList: action,
      syncaddresses: action,
      tracer: action,
      paymentLog: action,
      confirmCODOrder: action,
      renovaciones_fijos: action
    });
  }

  async getCart(init, loading) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart`,
      loading,
    };
    const response = await genericFunction(options, null, 'cart');
    this.getCartInfo = response;
    if (init === 'user_warned') {
      this.warnedUser = true;
    } else if (init === 'user_not_warned') {
      this.warnedUser = false;
    }

    return response;
  }

  setWarnedUser(init) {
    if (init === 'user_warned') {
      this.warnedUser = true;
    } else if (init === 'user_not_warned') {
      this.warnedUser = false;
    }
  }

  async getConfig() {
    const response = await queryFunction('config', getConfigMC);
    this.getCartConfig = response;
    return response;
  }

  async getConfigEsim() {
    const response = await queryFunction('config', getEsimConfig);
    this.getEsimConfig = response;
    return response;
  }

  async getConfigPagoContraEntrega() {
    const response = await queryFunction('config_pago_conta_entrega', getPagoContraEntregaConfig);
    this.getPagoContraEntregaConfig = response;
    return response;
  }

  async getExchangeRate() {
    // const options = {
    //   method: 'GET',
    //   url: `${process.env.NEXT_PUBLIC_SERVICES}/payments/htech/${process.env.NEXT_PUBLIC_MERCHANT}/exchange-rate`,
    // };
    // return genericFunction(options, null, 'exchangeRate');
    return { success: false };
  }

  async getRecommendations() {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/recommendations`,
    };
    return genericFunction(options, null, 'recommendations');
  }

  async getAddons() {
    return queryFunction('addons', apiAddons);
  }

  async addToCart(query, init) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/item/`,
      data: query,
    };

    const response = await genericFunction(options, null, 'addToCart');
    if (response.success) {
      this.getCartInfo = response;
      this.setWarnedUser(init);
    }
    return response;
  }

  async deleteToCart(partNumber) {
    const options = {
      method: 'DELETE',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/item/${partNumber}`,
    };

    const response = await genericFunction(options, null, 'deleteCart');
    if (response.success) {
      this.getCartInfo = response;
    }
    return response;
  }

  async validateCart(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/validate/`,
      data: query,
    };

    return genericFunction(options, null, 'validateCart');
  }

  async validateAddon(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/validateAddon`,
      data: query,
    };

    return genericFunction(options, null, 'validateCart');
  }

  async refreshOrder() {
    const options = {
      method: 'DELETE',
      url: `/api/custom/order/${process.env.NEXT_PUBLIC_MERCHANT}`,
    };

    const response = await genericFunction(options, null, 'deleteCart');
    this.getCartInfo = response;
    return response;
  }

  async addShippingAddress(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/shipping_address/`,
      data: query,
    };

    return genericFunction(options, null, 'shippingAddress');
  }

  async uploadDocument(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/upload/`,
      data: query,
    };

    return genericFunction(options, null, 'upload');
  }

  async addBillingAddress(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/billing_address/`,
      data: query,
    };

    return genericFunction(options, null, 'billingAddress');
  }

  async getNit(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/tl_backend/retrieveValidateDocumentODA/consultar`,
      data: query,
    };
    return genericFunction(options, null, 'validateNit');
  }

  async validateNit(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/tl_backend/retrieveValidateDocumentODA/validar`,
      data: query,
    };
    return genericFunction(options, null, 'validateNit');
  }

  async validateMaxActiveClient(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/mac`,
      data: query,
    };

    const response = await genericFunction(options, sessionIdDisable, 'validateMaxActiveClient');
    this.maxActivClientResponse = response;

    return response;
  }

  async validateMaxActivations(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/maxactivations`,
      data: {
        validateMaxActivations: query,
      },
    };

    const response = await genericFunction(options, null, 'validateMaxActivations');
    this.maxActivClientPostpagoResponse = response;

    return response;
  }

  async validateNumber(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/validateNumber`,
      data: query,
    };

    const response = await genericFunction(options, sessionIdDisable, 'validateNumber');
    this.validateNumberResponse = response;
    return response;
  }

  async generarToken(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/generarToken`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'generarToken');
  }

  async validarToken(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/validarToken`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'validarToken');
  }

  async orderDocument(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/orderDocument`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'orderDocument');
  }

  async contracts(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/contracts/`,
      data: query,
    };

    return genericFunction(options, null, 'contracts');
  }

  async documentsUpload(query) {
    const options = {
      method: 'POST',
      // url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/upload`,
      url: `${process.env.NEXT_PUBLIC_API}${process.env.NEXT_PUBLIC_MERCHANT}/cart/documents_upload/`,
      data: query,
    };

    return genericFunction(options, null, 'documentsUpload');
  }

  async addressList(query) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/findStores`,
      data: query,
    };

    const response = await genericFunction(options, null, 'addressList');
    this.addressListResponse = response;

    return response;
  }

  async syncaddresses(query) {
    const options = {
      method: 'PUT',
      url: `${process.env.NEXT_PUBLIC_ACCOUNTS}${process.env.NEXT_PUBLIC_MERCHANT}/person/syncaddresses`,
      data: query,
    };

    const response = await genericFunction(options, null, 'syncaddresses');
    this.addressListResponse = response;

    return response;
  }

  async tracer(query) {
    const sessionIdDisable = true;
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/${process.env.NEXT_PUBLIC_MERCHANT}/tracer`,
      data: query,
    };

    return genericFunction(options, sessionIdDisable, 'tracer');
  }

  async paymentLog(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_SERVICES}/payments/htech/log`,
      data: query,
    };

    return genericFunction(options, null, 'paymentLog');
  }

  async confirmCODOrder(orderid) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_SERVICES}/payments/htech/confirm/clarogt/pago-contra-entrega/${orderid}`,
      data: {},
    };

    return genericFunction(options, null, 'confirmCODOrder');
  }

  async renovaciones_fijos(query) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/config/renovaciones_fijos`,
      data: query,
    };

    const response = await genericFunction(options, null, 'renovaciones_fijos');
    this.addressListResponse = response;

    return response;
  }
}

export default new OrderStore();
